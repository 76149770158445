import cookie from 'cookie';
import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Link,
  Toolbar,
  Typography
} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import CookieContext from './CookieContext';

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  }
}));

function Header() {
  const cookieContext = useContext(CookieContext);
  const classes = useStyles();

  const cookies = cookie.parse(cookieContext.cookie);
  const cart = cookies.cart === undefined ? [] : JSON.parse(cookies.cart);

  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <Link className={classes.title} color="inherit" component={RouterLink} to="/">
          <Button color="primary">
            <Typography variant="h6">JC QUILEZ</Typography>
          </Button>
        </Link>
        <Link color="inherit" component={RouterLink} to="/cart">
          <IconButton aria-label="show le panier" color="inherit">
            <Badge badgeContent={cart.length} color="primary">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
        </Link>
      </Toolbar>
    </AppBar>
  );
}

export default Header;