import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline, Box } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import CookieContext from './CookieContext';
import TracksContext from './TracksContext';
import TrackContext from './TrackContext';
import Header from './Header';
import TrackList from './TrackList';
import Cart from './Cart';
import Player from './Player';

// Configure Firebase.
firebase.initializeApp({
  apiKey: 'AIzaSyBa2D_3gw8Ivxm6sdZC4Y4Ao0A05pSQw5c',
  projectId: 'jc-music-store',
  storageBucket: 'gs://jc-music-store.appspot.com'
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#00D646",
      main: "#00D646",
      dark: "#00D646"
    },
    secondary: {
      light: "#C7B299",
      main: "#C7B299",
      dark: "#C7B299"
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#FFFFFF",
      disabled: "#FFFFFF",
      hint: "#FFFFFF",
    },
    background: {
      default: "#324158",
      paper: "#324158",
    },
    action: {
      active: "#FFFFFF"
    }
  },
});

function App() {
  const [cookie, setCookie] = useState(document.cookie);
  const [tracks, setTracks] = useState(null);
  const [track, setTrack] = useState(null);

  useEffect(() => {
    fetch("https://sheets.googleapis.com/v4/spreadsheets/1eWbs0HrAXAQU_jyHoozswZneGKgRhObyzmTViT25vxs/values/Tracks?key=AIzaSyAa_NmHbD9Wwyzt6QYMwyp-hAjq2UUnA2g")
     .then(response => response.json())
     .then(data => setTracks(data.values.slice(1)));
}, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CookieContext.Provider value={{cookie, setCookie}}>
        <TracksContext.Provider value={{tracks, setTracks}}>
          <TrackContext.Provider value={{track, setTrack}}>
            <Router>
              <Header />
              <Switch>
                <Route exact path="/">
                  <TrackList />
                </Route>
                <Route exact path="/cart">
                  <Cart />
                </Route>
              </Switch>
              <Box my={10} />
              <Player />
            </Router>
          </TrackContext.Provider>
        </TracksContext.Provider>
      </CookieContext.Provider>
    </ThemeProvider>
  );
}

export default App;
