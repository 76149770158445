import React, { useState, useContext, useRef, useEffect } from 'react';
import TrackContext from './TrackContext';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Slider
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

function Player() {
  const trackContext = useContext(TrackContext);
  const requestRef = useRef();
  const [, setState] = useState();

  const isPlaying = trackContext.track && !trackContext.track.audio.paused;
  const totalTime = trackContext.track ? (isNaN(trackContext.track.audio.duration) ? 0 : trackContext.track.audio.duration) : 0;
  const currentTime = trackContext.track ? trackContext.track.audio.currentTime : 0;

  function forceUpdate() {
    // Force the component to re-render.
    setState({});

    requestRef.current = requestAnimationFrame(forceUpdate);
  }

  useEffect(() => {
    requestRef.current = requestAnimationFrame(forceUpdate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  function formatTime(secs) {
    var minutes = Math.floor(secs / 60) || 0;
    var seconds = (secs - minutes * 60) || 0;

    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }

  const handleSliderChange = (e, newValue) => {
    e.preventDefault();
    if(trackContext.track) {
      trackContext.track.audio.currentTime = totalTime * newValue / 1000;
    }
  };

  function play(e) {
    e.preventDefault();
    if(trackContext.track) {
      trackContext.track.audio.play();
    }
  }

  function pause(e) {
    e.preventDefault();
    if(trackContext.track) {
      trackContext.track.audio.pause();
    }
  }

  return (
    <Box
      position="fixed"
      bottom="0"
      width="100%">
      <Paper elevation={24}>
        <Box p={1}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              { !isPlaying &&
                <Button onClick={play}>
                  <PlayArrowIcon />
                </Button>
              }
              { isPlaying &&
                <Button onClick={pause}>
                  <PauseIcon />
                </Button>
              }
            </Grid>
            <Grid item>
              <Typography>
                {formatTime(Math.round(currentTime))}
              </Typography>
            </Grid>
            <Grid item xs>
              <Slider
                max={1000}
                style={{padding: "4px 0px"}}
                value={(currentTime / totalTime) * 1000}
                onChange={handleSliderChange}
                aria-labelledby="trackbar" />
            </Grid>
            <Grid item>
              <Typography>
                {formatTime(Math.round(totalTime))}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
  
export default Player;