import cookie from 'cookie';
import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Container,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Switch,
  Typography
} from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CookieContext from './CookieContext';
import TracksContext from './TracksContext';
import TrackContext from './TrackContext';

const useStyles = makeStyles(theme => ({
  accordion: {
    // Remove line between accordion.
    '&::before': { display : "none" },
  },
}));

function TrackList() {
  const classes = useStyles();
  const tracksContext = useContext(TracksContext);
  const [isGrouped, setIsgrouped] = useState(true);

  const groupedTracks = tracksContext.tracks ? tracksContext.tracks.reduce((acc, curr) => {
      const groups = curr[2].split(',');
      groups.forEach((item) => {
        const group = item.trim();
        const value = acc[group] ? [...acc[group], curr] : [curr];
        acc[group] = value;
      });
      return acc;
    }, {}) : {};

  const handleChange = (event) => {
    setIsgrouped(event.target.checked);
  };

  return (
    <Container>
      <Box display="flex" my={3}>
        <Box flexGrow={1}>
          <Typography variant="h4" gutterBottom>Pistes musicales</Typography>
        </Box>
        <FormControlLabel control={
          <Switch
            checked={isGrouped}
            onChange={handleChange}
            name="checkedA"
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }} />
          }
          labelPlacement="start"
          label="Grouper par album" />
      </Box>
      <Box my={3}>
        { isGrouped && groupedTracks && Object.keys(groupedTracks).map((key, index) => (
          <Accordion key={index} className={classes.accordion} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                <Box>{key}</Box>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box flexGrow={1}>
                <List>
                  {groupedTracks[key].map((item, index) => (
                    <Item key={index} index={index} item={item} />
                  ))}
                </List>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
        { !isGrouped &&
          <Paper>
            <List>
              {tracksContext.tracks && tracksContext.tracks.map((item, index) => (
                <Item key={index} index={index} item={item} />
              ))}
            </List>
          </Paper>
        }
      </Box>
    </Container>
  );
}

function Item({index, item}) {
  const cookieContext = useContext(CookieContext);
  const trackContext = useContext(TrackContext);

  function play(url) {
    const audio = trackContext.track ? trackContext.track.audio : new Audio();
    audio.src = url;
    audio.play();

    trackContext.setTrack({audio: audio, url: url});
  }

  function addToCart(index) {
    const cookies = cookie.parse(document.cookie);
    const cart = cookies.cart === undefined ? [] : JSON.parse(cookies.cart);

    // Prevent adding the same id twice.
    if(cart.indexOf(index) === -1 ) {
      cart.push(index);
      document.cookie = cookie.serialize("cart", JSON.stringify(cart));
      cookieContext.setCookie(document.cookie);
    }
  }

  return (
    <ListItem button selected={item[1]===(trackContext.track ? trackContext.track.url : "")}>
      <ListItemText primary={item[0]} onClick={() => play(item[1])} secondary={
        <span style={{marginLeft: "-5px"}}>
          { item[3].split(',').map((item, index) => (
            <Chip key={index} size="small" label={item} style={{margin: "6px 3px 0px", height:"20px"}} />
          ))}
          { item[4].split(',').map((item, index) => (
            <Chip key={index} size="small" color="secondary" label= {item} style={{margin: "6px 3px 0px", height:"20px"}} />
          ))}
        </span>
      }/>
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="ajouter au panier" onClick={() => addToCart(index)}>
          <AddShoppingCartIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default TrackList;