import cookie from 'cookie';
import React, { useContext, useState } from 'react';
import {
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Paper,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CookieContext from './CookieContext';
import TracksContext from './TracksContext';
import TrackContext from './TrackContext';

function Cart() {
  const cookieContext = useContext(CookieContext);
  const tracksContext = useContext(TracksContext);
  const trackContext = useContext(TrackContext);
  const [license, setLicense] = useState("createur");
  const prices = {
    "createur": 10,
    "professionel": 100,
    "digitale": 1000
  }

  // Get tracks from cart.
  var tracks = null;
  const cookies = cookie.parse(document.cookie);
  if(cookies.cart !== undefined && tracksContext.tracks != null) {
    const cart = JSON.parse(cookies.cart);
    tracks = cart.map(index => tracksContext.tracks[index]);
  }

  function play(url) {
    const audio = trackContext.track ? trackContext.track.audio : new Audio();
    audio.src = url;
    audio.play();

    trackContext.setTrack({audio: audio, url: url});
  }

  function removeFromCart(index) {
    const cookies = cookie.parse(document.cookie);
    const cart = JSON.parse(cookies.cart);
    cart.splice (index, 1);
    document.cookie = cookie.serialize("cart", JSON.stringify(cart));
    cookieContext.setCookie(document.cookie);
  }

  return (
    <Container>
      <Box my={3}>
        <Typography variant="h4" gutterBottom>Panier</Typography>
      </Box>
      { tracks === null &&
        <Box my={3}>
          <Typography>Votre panier est vide.</Typography>
        </Box>
      }
      { tracks && <>
        <Box my={3}>
          <Paper>
            <List>
            { tracks.map((item, index) => (
              <ListItem button key={index}>
                <ListItemText primary={item[0]} onClick={() => play(item[1])}/>
                <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="retirer du panier" onClick={() => removeFromCart(index)}>
                  <CloseIcon />
                </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            </List>
          </Paper>
        </Box>
        <Box my={3}>
          <Typography variant="h5" gutterBottom>Licence</Typography>
          <Licenses license={license} setLicense={setLicense} />
        </Box>
        <Box my={3}>
          <Typography variant="h5" gutterBottom>Prix</Typography>
          <Grid container>
              <Grid item xs>
                <Typography>Total :</Typography>
              </Grid>
              <Grid item>
                <Typography>€ {Object.keys(tracks).length * prices[license]}</Typography>
              </Grid>
          </Grid>
        </Box>
        <Box my={6} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary">Valider</Button>
        </Box>
      </> }
    </Container>
  );
}

function Licenses({license, setLicense}) {
  return (
      <RadioGroup aria-label="licenses" name="licenses" value={license} onChange={(event) => setLicense(event.target.value)}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header">
            <FormControlLabel
              aria-label="Select"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              value="createur"
              control={<Radio />}
              label="Créateur" />
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              Cette licence ne vous autorise pas à monétiser votre vidéo en ligne - par exemple sur YouTube. Ne convient pas à un usage publicitaire ou promotionnel.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions2-content"
            id="additional-actions2-header">
            <FormControlLabel
              aria-label="Select"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              value="professionel"
              control={<Radio />}
              label="Professionel" />
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              Cette licence couvre l'exploitation d'une vidéo visant à la promotion d'un produit, d'un service ou d'une marque. Productions faisant l'objet d'achat d'espace publicitaires au delà de 2000€ exclues.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions3-content"
            id="additional-actions3-header">
            <FormControlLabel
              aria-label="Select"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              value="digitale"
              control={<Radio />}
              label="Publicité Digitale" />
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary">
              This licence is for: a single online advert for YouTube (such as pre-roll) or a publisher platform (e.g. the Guardian, BuzzFeed, etc.); single use in a social media advert (e.g. Facebook sponsored post), with unlimited media spend.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </RadioGroup>
  );
}

export default Cart;